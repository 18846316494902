'use client';
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import { SvgIcon } from '@mui/material';

import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import ContactMailIcon from '@mui/icons-material/ContactMail';

import NextLink from 'next/link';
import { usePathname } from 'next/navigation';
import Image from 'next/image';

const navigation = [
    { name: 'Home', href: '/', current: true },
    { name: 'Dienstleistungen', href: '/dienstleitungen', current: false, subItems: [
            { name: 'Gravurarbeiten', href: '/dienstleitungen/gravurarbeiten', current: false },
            { name: 'Kabelkonfektionierung', href: '/dienstleitungen/kabelkonfektionierung', current: false },
            { name: 'Lagerlogistik', href: '/dienstleitungen/lagerlogistik', current: false },
            { name: 'Laser Blechbearbeitung', href: '/dienstleitungen/laser-blechbearbeitung', current: false },
            { name: 'Schaltschrankbau', href: '/dienstleitungen/schaltschrankbau', current: false },
            { name: 'Trockeneistrahlen', href: '/dienstleitungen/trockeneistrahlen', current: false },
        ] },
    { name: 'Unternehmen', href: '/unternehmen', current: false },
]

const Header = () => {
    const pathname = usePathname();
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const updatedNavigation = () => {
        return navigation.map(item => ({
            ...item,
            current: item.href === pathname,
        }));
    };
    const navigationData = updatedNavigation();
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <>
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            <Image src="/logo.svg" alt="IMS Logo" width={50} height={50} style={{ marginRight: '10px' }} />
                        </Typography>




                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="menue"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {/* Mobile Navigation */}
                                {navigationData.map((item, index) => (
                                    <div key={index}>
                                        {item.subItems ? (
                                            <>
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{ width: '100%', paddingLeft: 2, fontWeight: 'bold' }}
                                                >
                                                    {item.name}
                                                </Typography>
                                                {item.subItems.map((subItem, subIndex) => (
                                                    <MenuItem
                                                        key={`${index}-${subIndex}-${subItem.name}`}
                                                        component={NextLink}
                                                        href={subItem.href}
                                                        passHref
                                                        onClick={handleCloseNavMenu}
                                                    >
                                                        <a>{subItem.name}</a>
                                                    </MenuItem>
                                                ))}
                                            </>
                                        ) : (
                                            <MenuItem
                                                key={`${index}-${item.name}`}
                                                onClick={handleCloseNavMenu}
                                                component={NextLink}
                                                href={item.href}
                                                passHref
                                            >
                                                <Typography textAlign="center">{item.name}</Typography>
                                            </MenuItem>
                                        )}
                                    </div>
                                ))}
                            </Menu>
                        </Box>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            <Image src="/logo.svg" alt="IMS Logo" width={50} height={50} style={{ marginRight: '10px' }} />

                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {navigationData.map((item, index) => (
                                <div key={index}>

                                    {item.subItems ? (
                                        <>
                                            <Button
                                                onClick={handleClick}
                                                sx={{ my: 2, color: 'white', display: 'block' }}
                                            >
                                                {item.name}
                                            </Button>
                                            <Menu
                                                id={`menu-${item.name}`}
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleClose}
                                            >
                                                {item.subItems.map((subItem) => (
                                                    <MenuItem
                                                        key={subItem.name}
                                                        component={NextLink}
                                                        href={subItem.href}
                                                        passHref
                                                        onClick={handleClose}
                                                    >
                                                        <a>{subItem.name}</a>
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        </>
                                    ) : (
                                        <Button
                                            component={NextLink}
                                            href={item.href}
                                            passHref
                                            sx={{ my: 2, color: 'white', display: 'block' }}
                                            onClick={handleCloseNavMenu}
                                        >
                                            {item.name}
                                        </Button>
                                    )}

                                </div>
                            ))}
                        </Box>
                        <Button sx={{ display: { xs: 'none', md: 'flex' } }} startIcon={<ContactMailIcon />} component={NextLink} href="/kontakt" title="Kontakt Seite" variant="contained" color="warning">Kontakt</Button>
                        <Button sx={{ display: { xs: 'flex', md: 'none' } }} component={NextLink} href="/kontakt" title="Kontakt Seite" variant="contained" color="warning"><ContactMailIcon /></Button>
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
};

export default Header;